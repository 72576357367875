import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { LazyLoadImage }
  from 'react-lazy-load-image-component';
  import 'react-lazy-load-image-component/src/effects/blur.css';

import "bootstrap/dist/css/bootstrap.min.css";

export default function(props){
  const {
    store,
    social,
    categoryListData,
    socialListData,
    userData,

  } = props;
  const mainCategories = categoryListData?.filter(category => category?.parent_id === 0);

// Then, sort the main categories based on their IDs
mainCategories?.sort((a, b) => a.id - b.id);

const [visibleSubcategories, setVisibleSubcategories] = useState([]);

// Function to toggle visibility of subcategories
const toggleSubcategories = (mainCategoryId) => {
  if (visibleSubcategories.includes(mainCategoryId)) {
    setVisibleSubcategories(visibleSubcategories?.filter(id => id !== mainCategoryId));
  } else {
    setVisibleSubcategories([...visibleSubcategories, mainCategoryId]);
  }
};

console.log("socialListData",socialListData);
  return (
    <>
      
        <div className="footer1  pt-5 " >
          <div className="row  ">
            <div className="col-12 col-md-3">
              <ul className=" container mb-3 mb-md-0">
                <li className="p-1 p-md-3 ">
                  <LazyLoadImage effect="blur"  className="img img-fluid" src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/store/4/logo.webp" alt="Thaithingal Logo" />
                </li>
                <li
                  className="ps-md-5  pt-3 p-md-3 pb-3  "
                  style={{
                    color: "#ffffff",
                    fontFamily: "Alice",
                    whiteSpace:"nowrap",
                    fontSize: "19px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "30px",
                  }}
                >
                  Unit 101, 139, Desk No. RK 33, <br/>Oxford Towers,<br/>  HAL Old Airport Road,Kodihalli,<br/> Bengaluru, Karnataka - 560008,<br/>
                  Mobile :  7316980374 .<br/>

                   
                </li>
                <li className="ps-md-5  p-md-4 pb-3  " style={{
                    color: "#ffffff",
                    fontFamily: "Alice",
                    fontSize: "19px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "30px",
                    whiteSpace:"nowrap",
                  }}>Legal Entity : Thingal Essentials LLP</li>
              </ul>
            </div>

            <div className="col-12 col-md-3  ">
              <ul className="ps-1 ps-md-5  mb-3 mb-md-0">
               <li><h4>CATEGORIES</h4></li> 
               {mainCategories?.length > 0 ? (
                mainCategories.map((category) => (
                    <div className="pb-3">
                      <a className="" href={`/products/${category.seo_url}`}  value={category.id}>{category.name}</a>     
                  </div>
                ))
              ) : (
                <p>Loading categories...</p>
              )}
              </ul>
            </div>
            <div className="col-12 col-md-3  ">
              <ul className="ps-1 ps-md-5  mb-3 mb-md-0">
               <li><h4>INFORMATION</h4></li> 
                <li className="pb-3">
                  <a href="/pages/privacypolicy" target="_blank">Privacy Policy</a>
                </li>
                <li className="pb-3">
                  <a href="/pages/termsandconditions" target="_blank">Terms And Conditions</a>
                </li>
                <li className="pb-3">
                  <a href="/pages/shippingandrefundpolicy" target="_blank">
                    Shipping And Refund Policy
                  </a>
                </li>

                <li className="pb-3"><a href="/survey" target="_blank">Survey</a></li>
                <li className="pb-3">About Us</li>
                <li className="pb-3">Blog</li>
              </ul>
            </div>

            <div className="col-12 col-md-3  ">
              <ul className="ps-1 ps-md-5  mb-3 mb-md-0">
               <li> <h4>SUPPORT</h4></li>
               <li className="pb-3"><a href="/storelocation" target="_blank"> Store Location</a></li>
               <li className="pb-3"><a href="/trackorder" target="_blank"> Track Order</a></li>
                <li className="pb-3"><a href="/pages/contactus" target="_blank">Contact Us</a></li>
              </ul>
            </div>
          </div>

          <ul className=" ms-0 ps-0  footericons  mx-auto ">
            <li className="pb-2  ps-3 ps-md-5">
              <a href="https://www.facebook.com/thaithingal/" aria-label="Visit Our Facebook Page" target="_blank">
                <FontAwesomeIcon icon={faFacebookF} size="xl"  />
              </a>
            </li>
            <li className="pb-2 ps-3 ps-md-5">
              <a href="https://twitter.com/thaithingal/" target="_blank " aria-label="Visit Our twitter Page">
                <FontAwesomeIcon icon={faXTwitter} size="xl"  />
              </a>
            </li>
            <li className="pb-2 ps-3 ps-md-5">
              <a href="https://www.instagram.com/thaithingal_/" target="_blank" aria-label="Visit Our Fainstagramcebook Page">
                <FontAwesomeIcon icon={faInstagram} size="xl"  />
              </a>
            </li>
            <li className="pb-2 ps-3 ps-md-5">
              <a href="https://www.youtube.com/@user-qr9rg5mc8z" target="_blank" aria-label="Visit Our youtube Page" >
                <FontAwesomeIcon icon={faYoutube} size="xl"  />
              </a>
            </li>
          </ul>
          <div className="container-fluid p-1 p-md-5">
            <h3 className="pb-3 heading">About Us</h3>
            <p className="content pb-3" style={{ color: 'white' }}>Thaithingal is an online store offering a wide range of natural products. We are dedicated to promoting Tamil culture through our products, which include traditional medicines, herbal face packs, and much more. Our mission is to provide customers with high-quality, ethically sourced products that contribute to their health and well-being. With a focus on sustainability, we work closely with local farmers and artisans to ensure the authenticity and purity of our offerings. At Thaithingal, we believe in the power of nature to heal and rejuvenate, and our products reflect this philosophy. Whether you're looking for Ayurvedic remedies, organic skincare, or holistic wellness solutions, we have something for everyone. Join us on our journey to rediscover the natural treasures of Tamil Nadu and experience the beauty of traditional healing practices. With every purchase, you're not just buying a product – you're supporting a legacy of wellness and cultural heritage.</p>



            <h3 className="pb-3 heading">Our Products</h3>
            <p className="content pb-3" style={{ color: 'white' }}>At Thaithingal, we specialize in providing natural and authentic products that promote health and well-being. Our store offers a variety of items, including herbal remedies, organic skincare products, traditional medicines, and much more. Our commitment to quality and sustainability ensures that every product you purchase from us is carefully sourced and ethically produced. With a focus on preserving the environment, we strive to offer eco-friendly alternatives and support local artisans and farmers. Whether you're looking for natural supplements, herbal teas, or handcrafted skincare, we have something for everyone. Explore our collection to discover the benefits of Ayurveda and traditional Tamil medicine. Join us in our mission to promote holistic wellness and celebrate the beauty of Tamil culture through our products. Shop with confidence knowing that each purchase you make supports our efforts to preserve traditional practices and promote sustainable living. Experience the essence of Thaithingal and embark on a journey to wellness and vitality today!</p>

            <h3 className="pb-3 heading">Online Shipping or Direct Visit</h3>
            <p className="content pb-3" style={{ color: 'white' }}>Whether you prefer the convenience of online shopping or the experience of visiting our stores in Chennai and Madurai, Thaithingal offers flexible options to meet your needs. Our &nbsp; <a target='_blank' href="https://thaithingal.in/"aria-label="Visit our Thaithingal Eshop" style={{ color: '#345D00' }}><u> <b> www.thaithingal.in </b> </u> </a> &nbsp;online platform provides a seamless shopping experience, allowing you to browse our wide range of products from the comfort of your home and have them delivered directly to your doorstep. If you prefer a more personal touch, you can visit our stores in Chennai and Madurai to explore our products firsthand and receive expert assistance from our knowledgeable staff. Whichever option you choose, you can trust Thaithingal to provide high-quality, authentic products and exceptional customer service. Experience the best of Tamil culture and natural products with Thaithingal – your trusted partner for all your shopping needs.</p>



            <h3 className="pb-3 heading">Contact Us</h3>
            <p className="content pb-3" style={{ color: 'white' }}>If you have any questions or inquiries, please feel free to contact us at info@thaithingal.in or call us at +91 7316980374. Our friendly team is here to assist you! We strive to provide excellent customer service and are committed to your satisfaction. Whether you need help finding the right product or have a question about an order, we're always happy to help. Our knowledgeable staff is available to answer your queries and provide expert advice. At Thaithingal, customer satisfaction is our top priority. Don't hesitate to reach out to us – we're here to make your shopping experience enjoyable and hassle-free.</p>

          </div>
        </div>
        <footer className="footer container py-4 ">
       <div className="footercontents">
          <p className="p-0 m-0 mb-3" style={{
            color: "black", fontFamily: 'Alice',
            fontSize: '15px',
            fontWeight: 500, 
          }}>
            © 2024 <span>Thaithingal</span> All rights reserved. Developed & Maintained By <a href="https://www.vilvabusiness.com/" target="_blank" aria-label="Visit our Vilvabusiness "> <span> Vilva Business</span></a>.
          </p>

          <div  className="   mb-3">
            <LazyLoadImage effect="blur" className="mx-auto" src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/thaithingal/HomepageImages/footer-img.webp" alt="Footer Image" />
          </div>
          </div>
        </footer>
      
    </>
  );
};
